import { isClientSide } from "utils"

import { type Auth0Config } from "../auth/serverAuth"
import { type LoggedOrganizer } from "common/organizer"
import { type FeaturePreviewMode } from "../feature-toggle/featurePreviewMode"
import { type Theme } from "@infrastructure/themes/theme"

export const SERVER_CONTEXT_KEY = "SERVER_CONTEXT" as string

export interface ServerContext {
  isLocalDev: boolean
  baseUrl: string
  clientRollbarToken: string
  env: string
  gaTrackingId: string
  csrfToken?: string
  user?: LoggedOrganizer
  auth0Config: Auth0Config
  previewFeaturesAvailable: FeaturePreviewMode
  theme: Theme
  systemTheme?: Theme
}

export const getServerContext = (): ServerContext => {
  if (!hasServerContext()) {
    throw new Error("Server context not set in previous steps")
  }

  if (isClientSide()) {
    return JSON.parse((window as { [key: string]: any })[SERVER_CONTEXT_KEY])
  }

  return JSON.parse(process.env[SERVER_CONTEXT_KEY]!)
}

export const hasServerContext = (): boolean => {
  if (isClientSide()) {
    if (!(window as { [key: string]: any })[SERVER_CONTEXT_KEY]) {
      return false
    }
    return true
  }
  if (!process.env[SERVER_CONTEXT_KEY]) {
    return false
  }
  return true
}

export const saveServerContextOnServer = (context: ServerContext) => {
  process.env[SERVER_CONTEXT_KEY] = JSON.stringify(context)
}

export const serializeServerContextAsScript = (context: ServerContext) => (
  <script
    dangerouslySetInnerHTML={{
      __html: `window.${SERVER_CONTEXT_KEY}='${JSON.stringify(context)}'`
    }}
  />
)
