import { hydrate } from "react-dom"
import { BrowserRouter } from "react-router-dom"
import { ensureReady, After } from "@jaredpalmer/after"
import ReactModal from "react-modal"
import { afterJsRoutes as webRoutes } from "./website/routes"
import { getServerContext } from "./infrastructure/afterjs/serverContext"
import createCache from "@emotion/cache"
import { CacheProvider } from "@emotion/react"
import { ErrorBoundary } from "./infrastructure/afterjs/ErrorBoundary"
import { Provider, useRollbar } from "@rollbar/react"
import { type Configuration } from "rollbar"

ReactModal.setAppElement("#root")

const cache = createCache({ key: "main" })

const rollbarConfig: Configuration = {
  verbose: true,
  enabled: !getServerContext().isLocalDev,
  accessToken: getServerContext().clientRollbarToken,
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: getServerContext().env
}

ensureReady(webRoutes).then(data =>
  hydrate(
    <Provider config={rollbarConfig}>
      <CacheProvider value={cache}>
        <BrowserRouter>
          <ErrorBoundary rootUrl="/" backLinkText="Zpět na začátek naZávody.cz">
            <RollbarSetter />
            <After data={data as any} routes={webRoutes} transitionBehavior="blocking" />
          </ErrorBoundary>
        </BrowserRouter>
      </CacheProvider>
    </Provider>,
    document.getElementById("root")
  )
)

const RollbarSetter = () => {
  const rollbar = useRollbar()
  window.rollbar = rollbar
  return <></>
}

if (module.hot) {
  module.hot.accept()
}
