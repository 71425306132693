import { ExternalLink, LinkType } from "@website/components/Link/Link"
import Heading from "../components/Heading/Heading"
import { routes } from "../routes"
import LinkWithArrow from "@website/components/LinkWithArrow/LinkWithArrow"

export const ErrorPageContent: React.FC<{ rootUrl: string; backLinkText: string }> = ({ rootUrl, backLinkText }) => (
  <>
    <Heading type="h1" brand marginBottom>
      Oops, chyba někde u nás :(
    </Heading>
    <div className="mb-4 max-w-lg">
      <p className="mb-1/2">
        Moc se omlouváme za nepříjemnost, protože tohle je chyba někde u nás. Už jsme si poslali nějaké podrobnosti automaticky a budeme se to snažit vyřešit, co nejdřív. Zkuste to, prosím, za chvilku
        znova a případně nás{" "}
        <ExternalLink type={LinkType.email} to={routes.contact.getLink()}>
          kontaktujte
        </ExternalLink>
        .
      </p>
      <p className="text-red-600">
        Je také možné, že používate velmi starou verzi prohlížeče, kterou už nepodporujeme a proto zkontrolujte, jestli máte nejnovější verzi a případně ji aktualizujte. Pokud i poté přetrvají
        problémy, tak nás{" "}
        <ExternalLink type={LinkType.email} to={routes.contact.getLink()}>
          kontaktujte
        </ExternalLink>
        .
      </p>
    </div>
    <LinkWithArrow arrow="left" to={rootUrl}>
      {backLinkText}
    </LinkWithArrow>
  </>
)
