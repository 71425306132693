import { createElement } from "react"
import classNames from "classnames"

const brandColorClasses = classNames(`text-brand-primary dark:text-brand-dark-primary`)
const brandColorSecondaryClasses = classNames(`text-brand-secondary dark:text-brand-dark-primary`)
const currentColorClasses = classNames(`text-current`)
const defaultColorClasses = classNames(`text-gray-800 dark:text-slate-200`)
const h1Classes = classNames(`text-2xl md:text-3xl`)
const h2Classes = classNames(`text-xl md:text-2xl`)
const h3Classes = classNames(`text-lg md:text-xl`)
const h4Classes = classNames(`text-base md:text-lg`)
const h5Classes = classNames(`text-base`)
const h6Classes = classNames(`text-sm`)
const headingClasses = classNames(`font-bold`)
const leadClasses = classNames(`text-2xl md:text-6xl md:leading-[1.1]`)
const subLeadClasses = classNames(`text-2xl md:text-5xl md:leading-[1.1]`)
const marginBottomClasses = classNames(`mb-1/2`)
const marginTopClasses = classNames(`mt-1/2`)

export type HeadingTypes = "h1" | "h2" | "h3" | "h4" | "h5" | "h6"
export type HeadingSizes = HeadingTypes | "lead" | "subLead"

const sizeClassesMap: { [K in HeadingSizes]: string } = {
  lead: leadClasses,
  subLead: subLeadClasses,
  h1: h1Classes,
  h2: h2Classes,
  h3: h3Classes,
  h4: h4Classes,
  h5: h5Classes,
  h6: h6Classes
}

interface HeadingProps {
  brand?: boolean
  brandSecondary?: boolean
  children: React.ReactNode
  marginBottom?: boolean
  marginTop?: boolean
  size?: HeadingSizes
  type: HeadingTypes
  currentColor?: boolean
  align?: "left" | "center"
  lead?: boolean
}

export default function Heading({ type, size, children, brand, brandSecondary, marginBottom, marginTop, currentColor, align = "left" }: HeadingProps) {
  return createElement(
    type,
    {
      className: classNames(
        headingClasses,
        size && sizeClassesMap[size],
        !size && sizeClassesMap[type],
        (brand && brandColorClasses) || (brandSecondary && brandColorSecondaryClasses) || (currentColor && currentColorClasses) || defaultColorClasses,
        marginBottom && marginBottomClasses,
        marginTop && marginTopClasses,
        align === "center" && "md:text-center"
      )
    },
    children
  )
}
