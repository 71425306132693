import "../../baseAdmin.css"

import LoaderImage from "./loader.svg"

export default function Loader() {
  return (
    <div className="flex items-center justify-center h-screen bg-[#F5F5F5] dark:bg-zinc-950">
      <div className="w-[160px]">
        <img src={LoaderImage} alt="Obrázek nahrávám" />
        <div className="text-base text-center">Nahrávám</div>
      </div>
    </div>
  )
}
