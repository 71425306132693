import { asyncComponent } from "@jaredpalmer/after"
import { getServerContext } from "../afterjs/serverContext"
import Loader from "@website/components/Loader"

export interface Route<T extends unknown[] = []> {
  getLink: (...args: T) => string
  route: string
  exact: boolean
  component: any
}

export interface RouteWithBreadcrumb<T extends unknown[] = []> extends Route<T> {
  breadcrumb?: React.ReactNode
}

export function asyncRoute<T extends unknown[]>(route: string, componentLoader: () => Promise<any>, chunkName: string, linkCallback?: (...args: T) => string, exact?: boolean): Route<T> {
  return staticRoute<T>(route, createAsyncComponent(componentLoader, chunkName), linkCallback, exact)
}

export function staticRoute<T extends unknown[]>(route: string, component: any, linkCallback?: (...args: T) => string, exact?: boolean): Route<T> {
  return {
    getLink: linkCallback ? linkCallback : () => route,
    route: route,
    exact: exact === undefined ? true : exact,
    component: component
  }
}

export const createAsyncComponent = (componentLoader: () => Promise<any>, chunkName: string) =>
  asyncComponent({
    loader: componentLoader,
    Placeholder: () => loadingMessage,
    chunkName: chunkName
  })

export function getAbsoluteLink(parameterizedLink: string) {
  if (parameterizedLink.startsWith("http")) {
    return parameterizedLink
  }
  return getServerContext().baseUrl + parameterizedLink
}

const loadingMessage = <Loader />

export type AfterJsRoute = Route & { path: string }

export const convertToAfterJsRoutes = (...multipleRoutes: any[]): AfterJsRoute[] =>
  multipleRoutes.reduce(
    (acc, routes) => [
      ...acc,
      ...Object.keys(routes).map(routeName => {
        const route = routes[routeName]
        return { ...route, path: route.route }
      })
    ],
    []
  )
