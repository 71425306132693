import classNames from "classnames"
import { Link as RouterLink } from "react-router-dom"
import { HashLink } from "react-router-hash-link"

const linkClasses = classNames(`inline-flex gap-x-2`)
const linkSpanClasses = classNames(
  `group underline decoration-brand-primary decoration-2 underline-offset-2 transition-colors duration-200 ease-in-out hover:decoration-transparent dark:decoration-brand-dark-primary dark:hover:decoration-transparent`
)

export enum LinkType {
  external,
  email,
  tel,
  anchor
}

interface LinkProps {
  children: React.ReactNode
  to: string
  title?: string
  type?: LinkType
  iconStart?: React.ReactNode
}

export function ExternalLink({ children, iconStart, to, title, type = LinkType.external }: LinkProps) {
  let href = to
  let rel = "noopener noreferrer"
  let target = "_blank"

  if (type === LinkType.email) {
    href = `mailto:${to}`
    rel = ""
    target = "_self"
  } else if (type === LinkType.tel) {
    href = `tel:${to}`
    rel = ""
    target = "_self"
  } else if (type === LinkType.anchor) {
    href = `#${to}`
    rel = ""
    target = "_self"
  }

  return (
    <a className={classNames(linkClasses, type === LinkType.email && "w-full")} href={href} title={title} rel={rel} target={target}>
      {iconStart ? iconStart : null}
      <span className={classNames(linkSpanClasses, type === LinkType.email && "w-full min-w-0 break-words")}>{children}</span>
    </a>
  )
}

export function LinkWithHash({ children, iconStart, to }: LinkProps) {
  return (
    <HashLink className={linkClasses} to={to}>
      {iconStart ? iconStart : null}
      <span className={linkSpanClasses}>{children}</span>
    </HashLink>
  )
}

export default function Link({ children, iconStart, to }: LinkProps) {
  return (
    <RouterLink className={linkClasses} to={to}>
      {iconStart ? iconStart : null}
      <span className={linkSpanClasses}>{children}</span>
    </RouterLink>
  )
}
