import { convertToAfterJsRoutes, asyncRoute } from "@infrastructure/routes/routes"

export const orgBase = "/organizator"

export const loginLink = orgBase + "/prihlaseni"
export const logoutLink = orgBase + "/odhlaseni"

export const loggedOutLink = orgBase + "/odhlaseno"
export const notVerifiedLink = orgBase + "/uzivatel-nepotvrzeno"
export const userResultLink = orgBase + "/uzivatel-hotovo"
export const welcomeLink = orgBase + "/uzivatel/vitejte"

export const helpRoutes = {
  common: {
    ...asyncRoute(
      orgBase + "/napoveda",
      () => import(/* webpackChunkName: "org-help-contact" */ "./Help/ContactHelpPage"),
      "org-help-contact",
      () => orgBase + `/napoveda`
    ),
    breadcrumb: "Potřebujete poradit?"
  },
  changelog: {
    ...asyncRoute(
      orgBase + "/napoveda/novinky",
      () => import(/* webpackChunkName: "org-changelog" */ "./Help/ChangelogHelpPage"),
      "org-changelog",
      () => orgBase + `/napoveda/novinky`
    ),
    breadcrumb: "Novinky a změny"
  },
  billing: {
    ...asyncRoute(
      orgBase + "/napoveda/vyuctovani",
      () => import(/* webpackChunkName: "org-help-billing" */ "./Help/BillingHelpPage"),
      "org-help-demo",
      () => orgBase + `/napoveda/vyuctovani`
    ),
    breadcrumb: `Vyúčtování`
  },
  fio: {
    ...asyncRoute(
      orgBase + "/napoveda/fio-banka",
      () => import(/* webpackChunkName: "org-help-fio" */ "./Help/FioPage"),
      "org-help-fio",
      () => orgBase + `/napoveda/fio-banka`
    ),
    breadcrumb: `Párování Fio banka`
  },
  startNumbers: {
    ...asyncRoute(
      orgBase + "/napoveda/startovni-cisla",
      () => import(/* webpackChunkName: "org-help-start-number" */ "./Help/StartnumbersHelpPage"),
      "org-help-start-number",
      () => orgBase + `/napoveda/startovni-cisla`
    ),
    breadcrumb: `Startovní čísla`
  },
  categories: {
    ...asyncRoute(
      orgBase + "/napoveda/kategorie",
      () => import(/* webpackChunkName: "org-help-categories" */ "./Help/CategoriesHelpPage"),
      "org-help-categories",
      () => orgBase + `/napoveda/kategorie`
    ),
    breadcrumb: `Nastavení kategorií`
  },
  api: {
    ...asyncRoute(
      orgBase + "/napoveda/api",
      () => import(/* webpackChunkName: "org-help-api" */ "./Help/ApiHelpPage"),
      "org-help-api",
      () => orgBase + `/napoveda/api`
    ),
    breadcrumb: `API`
  }
} as const

export const editRaceRoutes = {
  editRaceInfo: {
    ...asyncRoute(
      orgBase + "/zavody/upravit-zavod-info/:id",
      () => import(/* webpackChunkName: "org-edit-race-info" */ "./EditRace/EditRaceInfoPage"),
      "org-edit-race-info",
      (raceId: number) => orgBase + `/zavody/upravit-zavod-info/${raceId}`,
      false
    ),
    breadcrumb: "Upravit základní info"
  },
  editRaceSubraces: {
    ...asyncRoute(
      orgBase + "/zavody/upravit-zavod-podzavody/:id",
      () => import(/* webpackChunkName: "org-edit-race-subraces" */ "./EditRace/EditRaceSubracesPage"),
      "org-edit-race-subraces",
      (raceId: number) => orgBase + `/zavody/upravit-zavod-podzavody/${raceId}`,
      false
    ),
    breadcrumb: "Upravit podzávody"
  },
  editRaceStartnumbers: {
    ...asyncRoute(
      orgBase + "/zavody/upravit-zavod-cisla/:id",
      () => import(/* webpackChunkName: "org-edit-race-startnumbers" */ "./EditRace/EditRaceStartnumbersPage"),
      "org-edit-race-startnumbers",
      (raceId: number) => orgBase + `/zavody/upravit-zavod-cisla/${raceId}`,
      false
    ),
    breadcrumb: "Startovní čísla"
  },
  editRaceEshop: {
    ...asyncRoute(
      orgBase + "/zavody/upravit-zavod-eshop/:id",
      () => import(/* webpackChunkName: "org-edit-race-eshop" */ "./EditRace/EditRaceEshopPage"),
      "org-edit-race-eshop",
      (raceId: number) => orgBase + `/zavody/upravit-zavod-eshop/${raceId}`,
      false
    ),
    breadcrumb: "Upravit eshop"
  },
  editRaceCoupons: {
    ...asyncRoute(
      orgBase + "/zavody/upravit-zavod-kupony/:id",
      () => import(/* webpackChunkName: "org-edit-race-coupons" */ "./EditRace/EditCouponsPage"),
      "org-edit-race-coupons",
      (raceId: number) => orgBase + `/zavody/upravit-zavod-kupony/${raceId}`,
      false
    ),
    breadcrumb: "Upravit slevové kupony"
  },
  editRaceActions: {
    ...asyncRoute(
      orgBase + "/zavody/upravit-zavod-akce/:id",
      () => import(/* webpackChunkName: "org-edit-race-actions" */ "./EditRace/EditRaceActionsPage"),
      "org-edit-race-actions",
      (raceId: number) => orgBase + `/zavody/upravit-zavod-akce/${raceId}`,
      false
    ),
    breadcrumb: "Další akce"
  },
  editRaceIronTime: {
    ...asyncRoute(
      orgBase + "/zavody/upravit-zavod-irontime/:id",
      () => import(/* webpackChunkName: "org-edit-race-irontime" */ "./Integrations/IronTimePage"),
      "org-edit-race-irontime",
      (raceId: number) => orgBase + `/zavody/upravit-zavod-irontime/${raceId}`,
      false
    ),
    breadcrumb: "IronTime"
  },
  editSubraceInfo: {
    ...asyncRoute(
      orgBase + "/zavody/upravit-podzavod-info/:id",
      () => import(/* webpackChunkName: "org-edit-subrace-info" */ "./EditSubrace/EditSubraceInfoPage"),
      "org-edit-subrace-info",
      (subraceId: number, tab?: string) => orgBase + `/zavody/upravit-podzavod-info/${subraceId}${tab ? "/" + tab : ""}`,
      false
    ),
    breadcrumb: "Upravit info podzávodu"
  },
  editSubraceCategories: {
    ...asyncRoute(
      orgBase + "/zavody/upravit-podzavod-kategorie/:id",
      () => import(/* webpackChunkName: "org-edit-subrace-categories" */ "./EditSubrace/EditSubraceCategoriesPage"),
      "org-edit-subrace-categories",
      (subraceId: number) => orgBase + `/zavody/upravit-podzavod-kategorie/${subraceId}`,
      false
    ),
    breadcrumb: "Upravit kategorie"
  },
  editSubraceForm: {
    ...asyncRoute(
      orgBase + "/zavody/upravit-podzavod-formular/:id",
      () => import(/* webpackChunkName: "org-edit-subrace-form" */ "./EditSubrace/EditSubraceFormPage"),
      "org-edit-subrace-form",
      (subraceId: number) => orgBase + `/zavody/upravit-podzavod-formular/${subraceId}`,
      false
    ),
    breadcrumb: "Upravit reg. formulář"
  },
  editSubraceLimits: {
    ...asyncRoute(
      orgBase + "/zavody/upravit-podzavod-omezeni/:id",
      () => import(/* webpackChunkName: "org-edit-subrace-limits" */ "./EditSubrace/EditSubraceLimitsPage"),
      "org-edit-subrace-limits",
      (subraceId: number) => orgBase + `/zavody/upravit-podzavod-omezeni/${subraceId}`,
      false
    ),
    breadcrumb: "Upravit omezení"
  },
  editSubracePayments: {
    ...asyncRoute(
      orgBase + "/zavody/upravit-podzavod-platby/:id",
      () => import(/* webpackChunkName: "org-edit-subrace-payments" */ "./EditSubrace/EditSubracePaymentsPage"),
      "org-edit-subrace-payments",
      (subraceId: number) => orgBase + `/zavody/upravit-podzavod-platby/${subraceId}`,
      false
    ),
    breadcrumb: "Upravit platby a ceny"
  },
  editSubraceEmails: {
    ...asyncRoute(
      orgBase + "/zavody/upravit-podzavod-emaily/:id",
      () => import(/* webpackChunkName: "org-edit-subrace-emails" */ "./EditSubrace/EditSubraceEmailsPage"),
      "org-edit-subrace-emails",
      (subraceId: number) => orgBase + `/zavody/upravit-podzavod-emaily/${subraceId}`,
      false
    ),
    breadcrumb: "Nastavení emailů"
  },
  onPlaceInfo: {
    ...asyncRoute(
      orgBase + "/zavody/na-miste-podzavod/:id",
      () => import(/* webpackChunkName: "org-onplace-surebrace-info" */ "./EditSubrace/OnPlaceInfoPage"),
      "org-onplace-surebrace-info",
      (subraceId: number) => orgBase + `/zavody/na-miste-podzavod/${subraceId}`,
      false
    ),
    breadcrumb: "Informace o registracích na místě"
  }
} as const

export const racesRoutes = {
  races: { ...asyncRoute(orgBase + "/zavody", () => import(/* webpackChunkName: "org-racelist" */ "./Racelist/RacelistPage"), "org-racelist"), breadcrumb: "Mé závody" },

  startlist: {
    ...asyncRoute(
      orgBase + "/zavody/startovka/:id",
      () => import(/* webpackChunkName: "org-startlist" */ "./Startlist/StartlistPage"),
      "org-startlist",
      (subraceid: number) => orgBase + `/zavody/startovka/${subraceid}`
    ),
    breadcrumb: "Startovní listina"
  },
  stats: {
    ...asyncRoute(
      orgBase + "/zavody/statistiky/:id",
      () => import(/* webpackChunkName: "org-race-summary" */ "./Statistics/RaceStatsSummaryPage"),
      "org-race-summary",
      (raceid: number) => orgBase + `/zavody/statistiky/${raceid}`
    ),
    breadcrumb: "Statistiky"
  },
  edit: {
    ...asyncRoute(
      orgBase + "/zavody/uprava-registrace/:id",
      () => import(/* webpackChunkName: "org-edit-registration" */ "./EditRegistration/EditRegistrationPage"),
      "org-edit-registration",
      (regid: number) => orgBase + `/zavody/uprava-registrace/${regid}`
    ),
    breadcrumb: "Upravit registraci"
  },
  duplicateRegistration: {
    ...asyncRoute(
      orgBase + "/zavody/kopie-registrace/:id/:subraceid",
      () => import(/* webpackChunkName: "org-copy-registration" */ "./EditRegistration/DuplicateRegistrationPage"),
      "org-edit-registration",
      (regid: number, subraceid: number) => orgBase + `/zavody/kopie-registrace/${regid}/${subraceid}`
    ),
    breadcrumb: "Kopírovat registraci"
  },
  ...editRaceRoutes
}

export const superAdminRoutes = {
  globalBilling: {
    ...asyncRoute(orgBase + "/global-vyuctovani", () => import(/* webpackChunkName: "org-global-billing" */ "./superadmin/GlobalBillingPage"), "org-global-billing"),
    breadcrumb: "Vyúčtování"
  }
} as const

export const privateRoutes = {
  welcome: { ...asyncRoute(welcomeLink, () => import(/* webpackChunkName: "org-welcome" */ "./user/WelcomePage"), "org-welcome"), breadcrumb: "Vítejte" },
  userSettings: { ...asyncRoute(orgBase + "/uzivatel", () => import(/* webpackChunkName: "org-usersettings" */ "./user/UserSettingsPage"), "org-usersettings"), breadcrumb: "Nastavení" },
  stripeConnected: {
    ...asyncRoute(orgBase + "/stripe-connected", () => import(/* webpackChunkName: "org-stripeconnected" */ "./user/stripe-connect/StripeConnectedPage"), "org-stripeconnected"),
    breadcrumb: "Nastavení"
  },
  billing: { ...asyncRoute(orgBase + "/vyuctovani", () => import(/* webpackChunkName: "org-billing" */ "./user/billing/RaceBillingPage"), "org-billing"), breadcrumb: "Vyúčtování" },
  payments: {
    ...asyncRoute(
      orgBase + "/platby",
      () => import(/* webpackChunkName: "org-payments" */ "./Payments/PaymentsPage"),
      "org-payments",
      () => orgBase + `/platby`
    ),
    breadcrumb: "Platby"
  },
  races: {
    ...asyncRoute(
      orgBase + "/zavody",
      () => import(/* webpackChunkName: "org-races" */ "./Layout/RacesLayout"),
      "org-races",
      () => orgBase + `/zavody`,
      false
    ),
    routes: {
      ...racesRoutes
    }
  },
  ...superAdminRoutes,
  help: {
    ...asyncRoute(
      orgBase + "/napoveda",
      () => import(/* webpackChunkName: "org-help" */ "./Help/HelpLayout"),
      "org-help",
      () => orgBase + `/napoveda`,
      false
    ),
    breadcrumb: "Potřebujete poradit?",
    routes: {
      ...helpRoutes
    }
  }
} as const

export const orgRoutes = {
  // auth
  loggedOut: { ...asyncRoute(loggedOutLink, () => import(/* webpackChunkName: "org-logout" */ "./user/LoggedOutPage"), "org-logout"), breadcrumb: "Odhlášení" },
  notVerified: { ...asyncRoute(notVerifiedLink, () => import(/* webpackChunkName: "org-not-verified" */ "./user/NotVerifiedPage"), "org-not-verified"), breadcrumb: "Uživatelský účet nepotvrzen" },
  userResult: { ...asyncRoute(userResultLink, () => import(/* webpackChunkName: "org-auth-result" */ "./user/ResultPage"), "org-auth-result"), breadcrumb: "Uživatelský účet - výsledek" },

  private: {
    ...asyncRoute(
      orgBase + "/",
      () => import(/* webpackChunkName: "org-private" */ "./Layout/PrivateLayout"),
      "org-private",
      () => orgBase + `/`,
      false
    ),
    breadcrumb: "Závody",
    routes: {
      ...privateRoutes
    }
  }
} as const

export const routes = {
  organizer: asyncRoute(
    orgBase,
    () => import(/* webpackChunkName: "org-racelist" */ "./Organizer"),
    "org-root",
    () => orgBase,
    false
  )
} as const

export const afterJsRoutes = [...convertToAfterJsRoutes(routes)]
