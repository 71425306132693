import { ErrorPageContent } from "@website/layout/ErrorPageContent"
import { ErrorBoundary as RollbarErrorBoundary } from "@rollbar/react"

interface Props {
  children: React.ReactNode
  rootUrl: string
  backLinkText: string
}

export const ErrorBoundary: React.FC<Props> = ({ children, rootUrl, backLinkText }) => {
  const Content = () => <ErrorPageContent rootUrl={rootUrl} backLinkText={backLinkText} />

  return <RollbarErrorBoundary fallbackUI={Content}>{children}</RollbarErrorBoundary>
}
