interface ArrowSmallRightIconProps {
  classnames?: string
  color?: string
}

export default function ArrowSmallRightIcon({ color = "currentColor", classnames }: ArrowSmallRightIconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke={color} className={`flex-shrink-0 ${classnames}`}>
      <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75" />
    </svg>
  )
}
