import ArrowSmallLeftIcon from "@website/svg/icons/ArrowSmallLeftIcon"
import ArrowSmallRightIcon from "@website/svg/icons/ArrowSmallRightIcon"
import classNames from "classnames"
import Link, { ExternalLink, type LinkType } from "../Link/Link"

const hiddenOnMobileClasses = classNames(`hidden md:flex`)
const linkWithArrowClasses = classNames(`inline-flex items-center gap-x-1`)

interface LinkWithArrowProps {
  arrow: "left" | "right"
  children: React.ReactNode
  to: string
  hiddenOnMobile?: boolean
  classnames?: string
  type?: LinkType
}

export default function LinkWithArrow({ arrow, children, hiddenOnMobile, to, classnames, type }: LinkWithArrowProps) {
  return (
    <div className={classNames(linkWithArrowClasses, hiddenOnMobile && hiddenOnMobileClasses, classnames)}>
      {arrow === "left" && <ArrowSmallLeftIcon classnames="w-5 h5" />}
      <div>
        {type ? (
          <ExternalLink type={type} to={to}>
            {children}
          </ExternalLink>
        ) : (
          <Link to={to}>{children}</Link>
        )}
      </div>
      {arrow === "right" && <ArrowSmallRightIcon classnames="w-5 h5" />}
    </div>
  )
}
